import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-86b319caf6/2/.yarn/berry/cache/next-npm-15.2.2-canary.4-e9c9fc7db8-10c0.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-86b319caf6/2/.yarn/berry/cache/next-npm-15.2.2-canary.4-e9c9fc7db8-10c0.zip/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-86b319caf6/2/.yarn/berry/cache/next-npm-15.2.2-canary.4-e9c9fc7db8-10c0.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-86b319caf6/2/.yarn/berry/cache/next-npm-15.2.2-canary.4-e9c9fc7db8-10c0.zip/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-86b319caf6/2/.yarn/berry/cache/next-npm-15.2.2-canary.4-e9c9fc7db8-10c0.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-86b319caf6/2/.yarn/berry/cache/next-npm-15.2.2-canary.4-e9c9fc7db8-10c0.zip/node_modules/next/dist/client/components/metadata/async-metadata.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-86b319caf6/2/.yarn/berry/cache/next-npm-15.2.2-canary.4-e9c9fc7db8-10c0.zip/node_modules/next/dist/client/components/metadata/metadata-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/.yarn/__virtual__/next-virtual-86b319caf6/2/.yarn/berry/cache/next-npm-15.2.2-canary.4-e9c9fc7db8-10c0.zip/node_modules/next/dist/client/components/render-from-template-context.js");
